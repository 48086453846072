import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { loginUser } from "../store/loggedUser-slice"
import styles from "./LoginScreen.module.css"
import logo from "../images/alfa-smart-logo.svg"

export const LoginScreen = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [loginInfo, setLoginInfo] = useState({
        id: "",
        password: "",
    })

    function handleChange(event) {
        const { name, value } = event.target
        setLoginInfo((prevOptData) => {
            return {
                ...prevOptData,
                [name]: value,
            }
        })
    }

    function loginHandler(userName, password) {
        const loginData = {
            userName: userName,
            password: password,
        }
        dispatch(loginUser(loginData))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            loginHandler(loginInfo.id, loginInfo.password)
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <div className={styles.container}>
            <img className={styles.logo} src={logo} alt="" />
            <form className={styles.form} onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="">Kullanıcı Adı</label>
                    <input
                        type="text"
                        name="id"
                        value={loginInfo.id}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <label htmlFor="">Şifre</label>
                    <input
                        type="password"
                        name="password"
                        value={loginInfo.password}
                        onChange={handleChange}
                    />
                </div>
                <div className={styles.buttonContainer}>
                    <button className={styles.formButton}>Giriş Yap</button>
                </div>
            </form>
        </div>
    )
}
