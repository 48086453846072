import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { LoadingScreen } from "../../screens/LoadingScreen"
import { fetchVehicleTrips } from "../../store/vehicleTrips-slice"
import styles from "./VehicleTrips.module.css"
import moment from "moment/moment"

export const VehicleTrips = () => {
    const params = useParams()
    const dispatch = useDispatch()
    const customers = useSelector((state) => state.customers)
    const customer = customers.data.customers.filter(
        (customer) => customer.musteriId === params.musteriId
    )
    const trips = useSelector((state) => state.vehicleTrips)
    const dd = new Date().toDateString()

    const d = moment(dd).format("YYYY-MM-DD")
    const [tripDate, setTripDate] = useState({
        date: d,
        renderDate: "",
    })

    console.log(tripDate)
    useEffect(() => {
        const date = moment(tripDate.date).format("DDMMYY")
        setTripDate((state) => {
            return {
                ...state,
                renderDate: moment(tripDate.date).format("DD/MM/YYYY"),
            }
        })
        const data = {
            apiKey: customer[0].apiKey,
            vehicleId: params.cihazId,
            date: date,
        }
        dispatch(fetchVehicleTrips(data))
    }, [])

    function handleChange(event) {
        const { name, value } = event.target
        setTripDate((prevOptData) => {
            return {
                ...prevOptData,
                [name]: value,
            }
        })
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            fetchVehicleTripsHandler()
            setTripDate((state) => {
                return {
                    ...state,
                    renderDate: moment(tripDate.date).format("DD/MM/YYYY"),
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    const fetchVehicleTripsHandler = () => {
        const date = moment(tripDate.date).format("DDMMYY")

        const data = {
            apiKey: customer[0].apiKey,
            vehicleId: params.cihazId,
            date: date,
        }
        dispatch(fetchVehicleTrips(data))
    }

    console.log(trips.data)
    let renderTrips
    if (trips.data !== null) {
        if (trips.data.hasOwnProperty("trip")) {
            if (trips.data.trip.length === 0) {
                renderTrips = <p>Yolculuk bulunamadı</p>
            } else {
                // console.log(trips.data.trip[2])
                renderTrips = trips.data.trip.map((trip, i) => {
                    return (
                        <div className={styles.vehicleContainer} key={i}>
                            <p>{trip.cihazId}</p>
                            <p>{trip.plaka}</p>
                            <p>{trip.aracTuru}</p>
                            <p>{trip.Tarih}</p>
                            <p>{trip.Saat}</p>
                            <p>{trip.Hiz}</p>
                            <p>{trip.lat}</p>
                            <p>{trip.lon}</p>
                        </div>
                    )
                })
            }
        }
    }

    return (
        <div className={styles.vehiclesContainer}>
            <div className={styles.vehiclesInnerContainer}>
                <p className={styles.leftContainer}>
                    {customer[0].irtibatKisisi} {params.cihazId} Numaralı
                    cihazın {tripDate.renderDate} tarihli yolculukları
                </p>
            </div>
            <div>
                <form onSubmit={handleSubmit}>
                    <input
                        type="date"
                        name="date"
                        value={tripDate.date}
                        onChange={handleChange}
                    />
                    <button>BUL</button>
                </form>
            </div>
            {trips.loading ? (
                <LoadingScreen />
            ) : (
                <div className={styles.vehiclesBottomContainer}>
                    {renderTrips}
                </div>
            )}
        </div>
    )
}
