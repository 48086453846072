import React from "react"
import styles from "./CustomerListBody.module.css"

import { NavLink, redirect, useNavigate } from "react-router-dom"

export const CustomersListBody = ({ customer, deleteCustomerHandler }) => {
    const navigate = useNavigate()

    const navigateHandler = () => {
        navigate(`/customer-vehicles/${customer.musteriId}`)
    }

    return (
        <tr className={styles.tableRow}>
            <td onClick={navigateHandler}>{customer.musteriId}</td>
            <td onClick={navigateHandler}>{customer.musteriKodu}</td>
            <td onClick={navigateHandler}>{customer.musteriUnvani}</td>
            <td onClick={navigateHandler}>{customer.irtibatKisisi}</td>
            <td onClick={navigateHandler}>{customer.telefon}</td>
            <td onClick={navigateHandler}>{customer.vergiDairesi}</td>
            <td onClick={navigateHandler}>{customer.vergiNo}</td>
            <td onClick={navigateHandler}>{customer.adres}</td>
            <td onClick={navigateHandler}>{customer.il}</td>
            <td onClick={navigateHandler}>{customer.ilce}</td>
            {/* <td>{customer.apiKey}</td> */}
            <td onClick={navigateHandler}>{customer.veritabaniAdi}</td>
            <td>
                <NavLink to={`/update-customer/${customer.musteriId}`}>
                    <i
                        className={`fa-solid fa-pen-to-square fa-lg ${styles.updateIcon}`}
                    ></i>
                </NavLink>
                <i
                    className={`fa-solid fa-trash fa-lg ${styles.deleteIcon}`}
                    onClick={deleteCustomerHandler.bind(
                        this,
                        customer.musteriId
                    )}
                ></i>
            </td>
        </tr>
    )
}
