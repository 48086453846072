import React from "react"
import styles from "./UserList.module.css"

export const UsersList = ({ renderItems }) => {
    return (
        <table className={styles.table}>
            <thead>
                <tr>
                    <th>Kullanıcı ID</th>
                    <th>Müşteri ID</th>
                    <th>İsim</th>
                    <th>Soyisim</th>
                    <th>Telefon</th>
                    <th>Kullanıcı adı</th>
                    <th>Şifre</th>
                    <th>Yetkiler</th>
                </tr>
            </thead>
            <tbody>{renderItems}</tbody>
        </table>
    )
}
