import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { updateCustomer } from "../../store/customer-slice"
import { CustomerInput } from "./CustomerInput"
import styles from "./UpdateCustomer.module.css"

export const UpdateCustomer = () => {
    const dispatch = useDispatch()
    const loggedUser = useSelector((state) => state.loggedUser)
    const customers = useSelector((state) => state.customers.data.customers)

    const params = useParams()
    console.log(params.musteriId)
    const customerstate = useSelector((state) => state.customer)
    const customer = customers.filter(
        (customer) => customer.musteriId === params.musteriId
    )
    const [updateCustomerInfo, setUpdateCustomerInfo] = useState({
        customerCode: customer[0].musteriKodu,
        customerName: customer[0].musteriUnvani,
        taxOffice: customer[0].vergiDairesi,
        taxIdNumber: customer[0].vergiNo,
        address: customer[0].adres,
        district: customer[0].ilce,
        province: customer[0].il,
        phoneNumber: customer[0].telefon,
        contactPerson: customer[0].irtibatKisisi,
        dbName: customer[0].veritabaniAdi,
    })
    console.log(customerstate.data)

    function handleChange(event) {
        const { name, value } = event.target
        setUpdateCustomerInfo((prevOptData) => {
            return {
                ...prevOptData,
                [name]: value,
            }
        })
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            updateCustomerHandler()
            setUpdateCustomerInfo({
                customerCode: "",
                customerName: "",
                taxOffice: "",
                taxIdNumber: "",
                address: "",
                district: "",
                province: "",
                phoneNumber: "",
                contactPerson: "",
                dbName: "",
            })
        } catch (error) {
            console.log(error)
        }
    }
    console.log(loggedUser)

    const updateCustomerHandler = () => {
        const data = {
            updateCustomerInfo: updateCustomerInfo,
            apiKey: loggedUser.data.apiKey,
            customerId: params.musteriId,
        }
        dispatch(updateCustomer(data))
    }
    return (
        <div className={styles.formContainer}>
            <CustomerInput
                customerInfo={updateCustomerInfo}
                buttonText={"Müşteri güncelle"}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
            />
        </div>
    )
}
