import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { fetchVehicle, updateVehicle } from "../../store/vehicle-slice"
import { VehicleInput } from "./VehicleInput"
import styles from "./UpdateVehicle.module.css"

export const UpdateVehicle = () => {
    const dispatch = useDispatch()
    const loggedUser = useSelector((state) => state.loggedUser)
    const params = useParams()
    const vehicle = useSelector((state) => state.vehicle)
    const data = {
        vehicleId: params.cihazId,
        apiKey: loggedUser.data.apiKey,
    }
    const [updateVehicleInfo, setUpdateVehicleInfo] = useState({
        vehicleSerialNo: "",
        deviceType: "",
        customerId: "",
        plate: "",
        vehicleType: "",
        phoneNumber: "",
        digitalIn1: "",
        digitalIn1Type: "",
        digitalIn2: "",
        digitalIn2Type: "",
        analogueIn1: "",
        analogueIn1Type: "",
        relay1: "",
        relay1Type: "",
        relay2: "",
        relay2Type: "",
    })

    useEffect(() => {
        fetchHandler()
    }, [dispatch])
    useEffect(() => {
        if (vehicle.data !== null) {
            if (vehicle.data.hasOwnProperty("vehicle")) {
                setUpdateVehicleInfo({
                    vehicleSerialNo: vehicle.data.vehicle.cihazSeriNo,
                    deviceType: vehicle.data.vehicle.cihazTipi,
                    customerId: vehicle.data.vehicle.musteriNo,
                    plate: vehicle.data.vehicle.plaka,
                    vehicleType: vehicle.data.vehicle.aracTuru,
                    phoneNumber: vehicle.data.vehicle.telefonNumarasi,
                    digitalIn1: vehicle.data.vehicle.dijital1,
                    digitalIn1Type: vehicle.data.vehicle.dijital1Tipi,
                    digitalIn2: vehicle.data.vehicle.dijital2,
                    digitalIn2Type: vehicle.data.vehicle.dijital2Tipi,
                    analogueIn1: vehicle.data.vehicle.analog1,
                    analogueIn1Type: vehicle.data.vehicle.analog1Tipi,
                    relay1: vehicle.data.vehicle.role1,
                    relay1Type: vehicle.data.vehicle.role1Tipi,
                    relay2: vehicle.data.vehicle.role2,
                    relay2Type: vehicle.data.vehicle.role2Tipi,
                })
                console.log("setstate")
            }
        }
    }, [vehicle.data])

    const fetchHandler = async () => {
        dispatch(fetchVehicle(data))
    }

    function handleChange(event) {
        const { name, value } = event.target
        setUpdateVehicleInfo((prevOptData) => {
            return {
                ...prevOptData,
                [name]: value,
            }
        })
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            updateVehicleHandler()
            setUpdateVehicleInfo({
                vehicleSerialNo: "",
                deviceType: "",
                customerId: "",
                plate: "",
                vehicleType: "",
                phoneNumber: "",
                digitalIn1: "",
                digitalIn1Type: "",
                digitalIn2: "",
                digitalIn2Type: "",
                analogueIn1: "",
                analogueIn1Type: "",
                relay1: "",
                relay1Type: "",
                relay2: "",
                relay2Type: "",
            })
        } catch (error) {
            console.log(error)
        }
    }

    const updateVehicleHandler = () => {
        const data = {
            updateVehicleInfo: updateVehicleInfo,
            apiKey: loggedUser.data.apiKey,
            vehicleId: params.cihazId,
        }
        dispatch(updateVehicle(data))
    }
    return (
        <div className={styles.formContainer}>
            {!!vehicle.loading ? (
                "loading"
            ) : (
                <VehicleInput
                    vehicleInfo={updateVehicleInfo}
                    buttonText={"Cihazı güncelle"}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                />
            )}
        </div>
    )
}
