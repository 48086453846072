import React from "react"
import { NavLink } from "react-router-dom"
import styles from "./VehiclesListBody.module.css"

export const VehiclesListBody = ({ vehicle, deleteVehicleHandler }) => {
    return (
        <tr className={styles.tableRow}>
            <td>{vehicle.cihazId}</td>
            <td>{vehicle.musteriUnvani}</td>
            <td>{vehicle.cihazTipi}</td>
            <td>{vehicle.aracTuru}</td>
            <td>{vehicle.cihazSeriNo}</td>
            <td>{vehicle.telefonNumarasi}</td>
            <td>{vehicle.plaka}</td>
            <td>
                <NavLink to={`/update-vehicle/${vehicle.cihazId}`}>
                    <i
                        className={`fa-solid fa-pen-to-square fa-lg ${styles.updateIcon}`}
                    ></i>
                </NavLink>
                <i
                    className={`fa-solid fa-trash fa-lg ${styles.deleteIcon}`}
                    onClick={deleteVehicleHandler.bind(this, vehicle.cihazId)}
                ></i>
            </td>
        </tr>
    )
}
