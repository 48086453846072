import "./App.css"
import styles from "./App.module.css"
import {
    Routes,
    Route,
    useNavigate,
    useLocation,
    useParams,
} from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"

import { LoginScreen } from "./screens/LoginScreen"
import { CustomersListScreen } from "./screens/CustomersListScreen"
import { LoadingScreen } from "./screens/LoadingScreen"
import { UpdateCustomer } from "./components/customer/UpdateCustomer"
import { Header } from "./components/Header/Header"
import { loggedUserActions } from "./store/loggedUser-slice"
import { PrivateRoute } from "./components/PrivateRoute/PrivateRoute"
import { AddCustomer } from "./components/customer/AddCustomer"
import { AddUser } from "./components/user/AddUser"

import { UsersListScreen } from "./screens/UsersListScreen"
import { UpdateUser } from "./components/user/UpdateUser"
import { VehiclesListScreen } from "./screens/VehiclesListScreen"
import { AddVehicle } from "./components/vehicle/AddVehicle"
import { UpdateVehicle } from "./components/vehicle/UpdateVehicle"
import { CustomerVehicles } from "./components/customer/CustomerVehicles"
import { Map } from "./components/map/Map"
import { VehicleTrips } from "./components/vehicle/VehicleTrips"

function App() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const loggedUser = useSelector((state) => state.loggedUser)
    const location = useLocation()

    console.log(loggedUser)
    useEffect(() => {
        if (loggedUser.data) {
            if (loggedUser.data.apiKey) {
                dispatch(loggedUserActions.authentication())
            }
        }
    })
    useEffect(() => {
        navigate("/customers")
    }, [loggedUser.isAuth])

    return (
        <div className="App">
            {loggedUser.isAuth && <Header />}
            <section
                className={
                    location.pathname.includes(
                        "/customer-vehicle/last-location/"
                    )
                        ? styles.mapCointainer
                        : styles.mainContainer
                }
            >
                <Routes>
                    <Route path="/login" element={<LoginScreen />} />
                    <Route
                        path="/customers"
                        element={
                            <PrivateRoute>
                                <CustomersListScreen />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/add-customer"
                        element={
                            <PrivateRoute>
                                <AddCustomer />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/update-customer/:musteriId"
                        element={
                            <PrivateRoute>
                                <UpdateCustomer />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/customer-vehicles/:musteriId"
                        element={
                            <PrivateRoute>
                                <CustomerVehicles />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/customer-vehicle/last-location/:musteriId/:cihazId"
                        element={
                            <PrivateRoute>
                                <Map />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/customer-vehicle/trips/:musteriId/:cihazId"
                        element={
                            <PrivateRoute>
                                <VehicleTrips />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/users"
                        element={
                            <PrivateRoute>
                                <UsersListScreen />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/add-user"
                        element={
                            <PrivateRoute>
                                <AddUser />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/update-user/:kullaniciId"
                        element={
                            <PrivateRoute>
                                <UpdateUser />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/vehicles"
                        element={
                            <PrivateRoute>
                                <VehiclesListScreen />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/add-vehicle"
                        element={
                            <PrivateRoute>
                                <AddVehicle />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/update-vehicle/:cihazId"
                        element={
                            <PrivateRoute>
                                <UpdateVehicle />
                            </PrivateRoute>
                        }
                    />
                </Routes>
            </section>
        </div>
    )
}

export default App
