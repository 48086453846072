import React from "react"
import styles from "./CustomerInput.module.css"

export const CustomerInput = ({
    handleChange,
    customerInfo,
    buttonText,
    handleSubmit,
}) => {
    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <div>
                <label>Müşteri Kodu</label>
                <input
                    type="text"
                    name="customerCode"
                    value={customerInfo.customerCode}
                    onChange={handleChange}
                />
            </div>
            <div>
                <label>Müşteri Ünvanı</label>
                <input
                    type="text"
                    name="customerName"
                    value={customerInfo.customerName}
                    onChange={handleChange}
                />
            </div>
            <div>
                <label>Vergi Dairesi</label>

                <input
                    type="text"
                    name="taxOffice"
                    value={customerInfo.taxOffice}
                    onChange={handleChange}
                />
            </div>
            <div>
                <label>Vergi Numarası</label>

                <input
                    type="text"
                    name="taxIdNumber"
                    value={customerInfo.taxIdNumber}
                    onChange={handleChange}
                />
            </div>
            <div>
                <label>Adres</label>

                <input
                    type="text"
                    name="address"
                    value={customerInfo.address}
                    onChange={handleChange}
                />
            </div>
            <div>
                <label htmlFor="">İl</label>
                <input
                    type="text"
                    name="district"
                    value={customerInfo.district}
                    onChange={handleChange}
                />
            </div>
            <div>
                <label htmlFor="">İlçe</label>

                <input
                    type="text"
                    name="province"
                    value={customerInfo.province}
                    onChange={handleChange}
                />
            </div>
            <div>
                <label htmlFor="">Telefon</label>
                <input
                    type="text"
                    name="phoneNumber"
                    value={customerInfo.phoneNumber}
                    onChange={handleChange}
                />
            </div>
            <div>
                <label htmlFor="">İletişim Kişisi</label>
                <input
                    type="text"
                    name="contactPerson"
                    value={customerInfo.contactPerson}
                    onChange={handleChange}
                />
            </div>
            <div>
                <label htmlFor="">VT Adı</label>
                <input
                    type="text"
                    name="dbName"
                    value={customerInfo.dbName}
                    onChange={handleChange}
                />
            </div>
            <div className={styles.buttonContainer}>
                <button className={styles.formButton}>{buttonText}</button>
            </div>
        </form>
    )
}
