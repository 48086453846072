import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { LoadingScreen } from "../../screens/LoadingScreen"
import { addCustomer } from "../../store/customer-slice"
import styles from "./AddCustomer.module.css"
import { CustomerInput } from "./CustomerInput"

export const AddCustomer = () => {
    const dispatch = useDispatch()
    const customer = useSelector((state) => state.customer)
    const userApiKey = useSelector((state) => state.loggedUser.data.apiKey)
    const [addCustomerInfo, setAddCustomerInfo] = useState({
        customerCode: "",
        customerName: "",
        taxOffice: "",
        taxIdNumber: "",
        address: "",
        district: "",
        province: "",
        phoneNumber: "",
        contactPerson: "",
        dbName: "",
    })

    function handleChange(event) {
        const { name, value } = event.target
        setAddCustomerInfo((prevOptData) => {
            return {
                ...prevOptData,
                [name]: value,
            }
        })
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            addCustomerHandler()
            setAddCustomerInfo({
                customerCode: "",
                customerName: "",
                taxOffice: "",
                taxIdNumber: "",
                address: "",
                district: "",
                province: "",
                phoneNumber: "",
                contactPerson: "",
                dbName: "",
            })
        } catch (error) {
            console.log(error)
        }
    }

    const addCustomerHandler = () => {
        const data = {
            addCustomerInfo: addCustomerInfo,
            apiKey: userApiKey,
        }
        dispatch(addCustomer(data))
    }
    console.log(addCustomerInfo)
    return (
        <div className={styles.formContainer}>
            {customer.loading ? (
                <LoadingScreen />
            ) : (
                <CustomerInput
                    buttonText={"MÜŞTERİ EKLE"}
                    customerInfo={addCustomerInfo}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                />
            )}
        </div>
    )
}
