import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { updateCustomer } from "../../store/customer-slice"
import { updateUser } from "../../store/user-slice"
import { CustomerInput, UserInput } from "./UserInput"

import styles from "./UpdateUser.module.css"

export const UpdateUser = () => {
    const dispatch = useDispatch()
    const loggedUser = useSelector((state) => state.loggedUser)
    const users = useSelector((state) => state.users.data.users)

    const params = useParams()
    console.log(params.kullaniciId)
    const customerstate = useSelector((state) => state.customer)
    const user = users.filter(
        (customer) => customer.kullaniciId === params.kullaniciId
    )
    console.log(user)
    const [updateUserInfo, setUpdateUserInfo] = useState({
        customerId: user[0].musteriId,
        name: user[0].ad,
        surname: user[0].soyad,
        phoneNumber: user[0].telefon,
        email: user[0].eposta,
        userName: user[0].kullaniciAdi,
        password: user[0].sifre,
        userRights: user[0].yetkiler,
    })
    console.log(customerstate.data)

    function handleChange(event) {
        const { name, value } = event.target
        setUpdateUserInfo((prevOptData) => {
            return {
                ...prevOptData,
                [name]: value,
            }
        })
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            updateUserHandler()
            setUpdateUserInfo({
                customerId: "",
                name: "",
                surname: "",
                phoneNumber: "",
                email: "",
                userName: "",
                password: "",
                userRights: "",
            })
        } catch (error) {
            console.log(error)
        }
    }

    const updateUserHandler = () => {
        const data = {
            updateUserInfo: updateUserInfo,
            apiKey: loggedUser.data.apiKey,
            userId: params.kullaniciId,
        }
        dispatch(updateUser(data))
    }
    return (
        <div className={styles.formContainer}>
            <UserInput
                userInfo={updateUserInfo}
                buttonText={"Kullanıcı güncelle"}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
            />
        </div>
    )
}
