import React from "react"
import styles from "./VehicleInput.module.css"

export const VehicleInput = ({
    handleChange,
    vehicleInfo,
    buttonText,
    handleSubmit,
}) => {
    return (
        <form className={styles.form} onSubmit={handleSubmit}>
            <div>
                <label htmlFor="">Araç Seri No</label>
                <input
                    type="text"
                    name="vehicleSerialNo"
                    value={vehicleInfo.vehicleSerialNo}
                    onChange={handleChange}
                />
            </div>
            <div>
                <label htmlFor="">Cihaz Tipi</label>
                <input
                    type="text"
                    name="deviceType"
                    value={vehicleInfo.deviceType}
                    onChange={handleChange}
                />
            </div>
            <div>
                <label htmlFor="">Müşteri ID</label>
                <input
                    type="text"
                    name="customerId"
                    value={vehicleInfo.customerId}
                    onChange={handleChange}
                />
            </div>
            <div>
                <label htmlFor="">Plaka</label>
                <input
                    type="text"
                    name="plate"
                    value={vehicleInfo.plate}
                    onChange={handleChange}
                />
            </div>
            <div>
                <label htmlFor="">Araç Tipi</label>
                <input
                    type="text"
                    name="vehicleType"
                    value={vehicleInfo.vehicleType}
                    onChange={handleChange}
                />
            </div>
            <div>
                <label htmlFor="">Telefon Numarası</label>
                <input
                    type="text"
                    name="phoneNumber"
                    value={vehicleInfo.phoneNumber}
                    onChange={handleChange}
                />
            </div>
            <div>
                <label htmlFor="">Dijital 1</label>
                <input
                    type="text"
                    name="digitalIn1"
                    value={vehicleInfo.digitalIn1}
                    onChange={handleChange}
                />
            </div>
            <div>
                <label htmlFor="">Dijital 1 Tipi</label>
                <input
                    type="text"
                    name="digitalIn1Type"
                    value={vehicleInfo.digitalIn1Type}
                    onChange={handleChange}
                />
            </div>
            <div>
                <label htmlFor="">Dijital 2</label>
                <input
                    type="text"
                    name="digitalIn2"
                    value={vehicleInfo.digitalIn2}
                    onChange={handleChange}
                />
            </div>
            <div>
                <label htmlFor="">Dijital 2 Tipi</label>
                <input
                    type="text"
                    name="digitalIn2Type"
                    value={vehicleInfo.digitalIn2Type}
                    onChange={handleChange}
                />
            </div>
            <div>
                <label htmlFor="">Analog 1</label>
                <input
                    type="text"
                    name="analogueIn1"
                    value={vehicleInfo.analogueIn1}
                    onChange={handleChange}
                />
            </div>
            <div>
                {" "}
                <label htmlFor="">Analog 1 Tipi</label>
                <input
                    type="text"
                    name="analogueIn1Type"
                    value={vehicleInfo.analogueIn1Type}
                    onChange={handleChange}
                />
            </div>

            <div>
                <label htmlFor="">Role 1</label>
                <input
                    type="text"
                    name="relay1"
                    value={vehicleInfo.relay1}
                    onChange={handleChange}
                />
            </div>
            <div>
                <label htmlFor="">Role 1 Tipi</label>
                <input
                    type="text"
                    name="relay1Type"
                    value={vehicleInfo.relay1Type}
                    onChange={handleChange}
                />
            </div>
            <div>
                <label htmlFor="">Role 2</label>
                <input
                    type="text"
                    name="relay2"
                    value={vehicleInfo.relay2}
                    onChange={handleChange}
                />
            </div>
            <div>
                <label htmlFor="">Role 2 Tipi</label>
                <input
                    type="text"
                    name="relay2Type"
                    value={vehicleInfo.relay2Type}
                    onChange={handleChange}
                />
            </div>

            <div className={styles.buttonContainer}>
                <button className={styles.formButton}>{buttonText}</button>
            </div>
        </form>
    )
}
