import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const userSlice = createSlice({
    name: "user",
    initialState: {
        data: null,
        loading: false,
        error: "",
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(addUser.pending, (state, action) => {
            state.loading = true
            state.error = ""
        })
        builder.addCase(addUser.fulfilled, (state, action) => {
            state.data = action.payload
            state.loading = false
        })
        builder.addCase(addUser.rejected, (state, action) => {
            state.loading = false
            state.error = "Error while adding user"
        })
        builder.addCase(updateUser.pending, (state, action) => {
            state.loading = true
            state.error = ""
        })
        builder.addCase(updateUser.fulfilled, (state, action) => {
            state.data = action.payload
            state.loading = false
        })
        builder.addCase(updateUser.rejected, (state, action) => {
            state.loading = false
            state.error = "Error while updating user"
        })
        builder.addCase(deleteUser.pending, (state, action) => {
            state.loading = true
            state.error = ""
        })
        builder.addCase(deleteUser.fulfilled, (state, action) => {
            state.data = action.payload
            state.loading = false
        })
        builder.addCase(deleteUser.rejected, (state, action) => {
            state.loading = false
            state.error = "Error while deleting user"
        })
    },
})

export const addUser = createAsyncThunk("addUser", async (data) => {
    const url = "https://wa.dukateknoloji.com/users/"
    const config = {
        headers: {
            apiKey: data.apiKey,
            "Content-Type": "application/x-www-form-urlencoded",
        },
    }
    const response = await axios.post(url, data.addUserInfo, config)
    return response.data
})

export const updateUser = createAsyncThunk("updateUser", async (data) => {
    const url = `https://wa.dukateknoloji.com/users/${data.userId}/`
    const config = {
        headers: {
            apiKey: data.apiKey,
            "Content-Type": "application/x-www-form-urlencoded",
        },
    }
    console.log(data.updateUserInfo)

    const response = await axios.put(url, data.updateUserInfo, config)
    return response.data
})

export const deleteUser = createAsyncThunk("deleteUser", async (data) => {
    const url = `https://wa.dukateknoloji.com/users/${data.userId}/`
    const config = {
        headers: {
            apiKey: data.apiKey,
            "Content-Type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Credentials": true,
            "Access-Control-Allow-Origin": true,
            "Access-Control-Allow-Headers": true,
        },
    }
    const response = await axios.delete(url, config)
    return response.data
})

export const userActions = userSlice.actions

export default userSlice
