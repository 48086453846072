import React from "react"
import styles from "./UserInput.module.css"

export const UserInput = ({
    handleChange,
    userInfo,
    buttonText,
    handleSubmit,
}) => {
    return (
        <form className={styles.form} onSubmit={handleSubmit}>
            <div>
                <label htmlFor="">Müşteri ID</label>
                <input
                    type="text"
                    name="customerId"
                    value={userInfo.customerId}
                    onChange={handleChange}
                />
            </div>
            <div>
                <label htmlFor="">İsim</label>
                <input
                    type="text"
                    name="name"
                    value={userInfo.name}
                    onChange={handleChange}
                />
            </div>
            <div>
                <label htmlFor="">Soyisim</label>
                <input
                    type="text"
                    name="surname"
                    value={userInfo.surname}
                    onChange={handleChange}
                />
            </div>
            <div>
                <label htmlFor="">Telefon Numarası</label>
                <input
                    type="text"
                    name="phoneNumber"
                    value={userInfo.phoneNumber}
                    onChange={handleChange}
                />
            </div>

            <div>
                <label htmlFor="">Email</label>
                <input
                    type="text"
                    name="email"
                    value={userInfo.email}
                    onChange={handleChange}
                />
            </div>
            <div>
                <label htmlFor="">Kullanıcı Adı</label>

                <input
                    type="text"
                    name="userName"
                    value={userInfo.userName}
                    onChange={handleChange}
                />
            </div>
            <div>
                <label htmlFor="">Şifre</label>
                <input
                    type="text"
                    name="password"
                    value={userInfo.password}
                    onChange={handleChange}
                />
            </div>
            <div>
                <label htmlFor="">Kullanıcı Hakları</label>
                <input
                    type="text"
                    name="userRights"
                    value={userInfo.userRights}
                    onChange={handleChange}
                />
            </div>
            <div className={styles.buttonContainer}>
                <button className={styles.formButton}>{buttonText}</button>
            </div>
        </form>
    )
}
