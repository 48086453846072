import styles from "./CustomersList.module.css"

export const CustomersList = ({ renderItems }) => {
    return (
        <table className={styles.table}>
            <thead>
                <tr>
                    <th>Müşteri ID</th>
                    <th>Müşteri Kodu</th>
                    <th>Müşteri Ünvanı</th>
                    <th>İrtibat Kişisi</th>
                    <th>Telefon</th>
                    <th>Vergi Dairesi</th>
                    <th>vergi No</th>
                    <th>Adres</th>
                    <th>İl</th>
                    <th>İlçe</th>
                    {/* <th>api key</th> */}
                    <th>vt adı</th>
                </tr>
            </thead>
            <tbody>{renderItems}</tbody>
        </table>
    )
}
