import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { LoadingScreen } from "../../screens/LoadingScreen"
import { fetchActiveCustomerVehicles } from "../../store/customerActiveVehicles-slice"
import { fetchCustomerVehicles } from "../../store/customerVehicles-slice"
import styles from "./CustomerVehicles.module.css"

export const CustomerVehicles = () => {
    const params = useParams()
    const dispatch = useDispatch()
    const customers = useSelector((state) => state.customers)
    const customer = customers.data.customers.filter(
        (customer) => customer.musteriId === params.musteriId
    )
    const customerActiveVehicles = useSelector(
        (state) => state.customerActiveVehicles
    )
    const customerVehicles = useSelector((state) => state.customerVehicles)

    useEffect(() => {
        const data = {
            apiKey: customer[0].apiKey,
            customerId: customer[0].musteriId,
        }
        dispatch(fetchActiveCustomerVehicles(data))
        dispatch(fetchCustomerVehicles(data))
    }, [])
    console.log(customerVehicles)

    const navigate = useNavigate()

    const navigateLastLocationHandler = (vehicleId) => {
        navigate(
            `/customer-vehicle/last-location/${customer[0].musteriId}/${vehicleId}`
        )
    }
    const navigateToTripsHandler = (vehicleId) => {
        navigate(
            `/customer-vehicle/trips/${customer[0].musteriId}/${vehicleId}/`
        )
    }
    console.log(customerActiveVehicles)
    let renderActiveVehicles
    let renderVehicles
    if (customerActiveVehicles.data !== null) {
        if (customerActiveVehicles.data.hasOwnProperty("aracbilgileri")) {
            if (customerActiveVehicles.data.aracbilgileri.length === 0) {
                renderActiveVehicles = <p>Aktif cihaz bulunamadı</p>
            } else {
                renderActiveVehicles =
                    customerActiveVehicles.data.aracbilgileri.map((vehicle) => {
                        return (
                            <div
                                className={styles.vehicleContainer}
                                key={vehicle.plaka}
                            >
                                <p>{vehicle.cihazId}</p>

                                <p>{vehicle.plaka}</p>
                                <p>{vehicle.aracTuru}</p>
                                <p>{vehicle.Tarih}</p>
                                <p>{vehicle.Saat}</p>
                                <p>{vehicle.Hiz}</p>
                                <p>{vehicle.lat}</p>
                                <p>{vehicle.lon}</p>
                                <button
                                    onClick={navigateLastLocationHandler.bind(
                                        this,
                                        vehicle.cihazId
                                    )}
                                >
                                    SON KONUMU GÖR
                                </button>
                                <button
                                    onClick={navigateToTripsHandler.bind(
                                        this,
                                        vehicle.cihazId
                                    )}
                                >
                                    Yolculukları gör
                                </button>
                            </div>
                        )
                    })
            }
        }
    }
    if (customerVehicles.data !== null) {
        if (customerVehicles.data.hasOwnProperty("vehicles")) {
            if (customerVehicles.data.vehicles.length === 0) {
                renderVehicles = <p>Aktif cihaz bulunamadı</p>
            } else {
                renderVehicles = customerVehicles.data.vehicles.map(
                    (vehicle) => {
                        return (
                            <div
                                className={styles.vehicleContainer}
                                key={vehicle.plaka}
                            >
                                <p>{vehicle.cihazId}</p>
                                <p>{vehicle.cihazSeriNo}</p>

                                <p>{vehicle.plaka}</p>
                                <p>{vehicle.aracTuru}</p>
                                <p>{vehicle.telefonNumarasi}</p>
                            </div>
                        )
                    }
                )
            }
        }
    }

    return (
        <div className={styles.vehiclesContainer}>
            <div className={styles.vehiclesInnerContainer}>
                <p className={styles.leftContainer}>
                    {customer[0].irtibatKisisi} aktif cihazları
                </p>
            </div>
            {customerActiveVehicles.loading ? (
                <LoadingScreen />
            ) : (
                <div className={styles.vehiclesBottomContainer}>
                    {renderActiveVehicles}
                    {/* <div className={styles.vehicleContainer}>
                        <p>1</p>

                        <p>2</p>
                        <p>3</p>
                        <p>44</p>
                        <p>66</p>
                        <p>77</p>
                        <p>77</p>
                        <p>77771</p>
                    </div> */}
                </div>
            )}
            <div className={styles.vehiclesInnerContainer}>
                <p className={styles.leftContainer}>
                    {customer[0].irtibatKisisi} cihazları
                </p>
            </div>
            {customerActiveVehicles.loading ? (
                <LoadingScreen />
            ) : (
                <div className={styles.vehiclesBottomContainer}>
                    {renderVehicles}
                    {/* <div className={styles.vehicleContainer}>
                        <p>1</p>
                        <p>2</p>
                        <p>3</p>
                        <p>44</p>
                        <p>66</p>
                        <p>77</p>
                        <p>77</p>
                        <p>77771</p>
                    </div> */}
                </div>
            )}
        </div>
    )
}
