import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"

const vehicleTripsSlice = createSlice({
    name: "vehicleTripsSlice",
    initialState: {
        data: null,
        loading: false,
        error: "",
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchVehicleTrips.pending, (state, action) => {
            state.loading = true
            state.error = ""
        })
        builder.addCase(fetchVehicleTrips.fulfilled, (state, action) => {
            state.data = action.payload
            state.loading = false
        })
        builder.addCase(fetchVehicleTrips.rejected, (state, action) => {
            state.loading = false
            state.error = "Error while fetching vehicle trips"
        })
    },
})

export const fetchVehicleTrips = createAsyncThunk(
    "fetchVehicleTrips",
    async (data) => {
        const url = ` https://wa.dukateknoloji.com/customervehicles/trip/${data.vehicleId}/${data.date}/`
        const config = {
            headers: {
                apiKey: data.apiKey,

                "Content-Type": "application/x-www-form-urlencoded",
            },
        }
        const response = await axios.get(url, config)
        return response.data
    }
)

export const vehicleTripsActions = vehicleTripsSlice.actions

export default vehicleTripsSlice
