import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"

const customerActiveVehiclesSlice = createSlice({
    name: "customerActiveVehicles",
    initialState: {
        data: null,
        loading: false,
        error: "",
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(
            fetchActiveCustomerVehicles.pending,
            (state, action) => {
                state.loading = true
                state.error = ""
            }
        )
        builder.addCase(
            fetchActiveCustomerVehicles.fulfilled,
            (state, action) => {
                state.data = action.payload
                state.loading = false
            }
        )
        builder.addCase(
            fetchActiveCustomerVehicles.rejected,
            (state, action) => {
                state.loading = false
                state.error = "Error while fetching vehicles"
            }
        )
    },
})

export const fetchActiveCustomerVehicles = createAsyncThunk(
    "fetchActiveCustomerVehicles",
    async (data) => {
        const url =
            "https://wa.dukateknoloji.com/customervehicles/lastlocation/"
        const config = {
            headers: {
                apiKey: data.apiKey,
                "Content-Type": "application/x-www-form-urlencoded",
            },
        }
        const response = await axios.get(url, config)
        return response.data
    }
)

export const customerActiveVehiclesActions = customerActiveVehiclesSlice.actions

export default customerActiveVehiclesSlice
