import { configureStore } from "@reduxjs/toolkit"
import customerSlice from "./customer-slice"
import customerActiveVehiclesSlice from "./customerActiveVehicles-slice"
import customersSlice from "./customers-slice"
import customerVehiclesSlice from "./customerVehicles-slice"
import loggedUserSlice from "./loggedUser-slice"
import userSlice from "./user-slice"
import usersSlice from "./users-slice"
import vehicleSlice from "./vehicle-slice"
import vehiclesSlice from "./vehicles-slice"
import vehicleTripsSlice from "./vehicleTrips-slice"

const store = configureStore({
    reducer: {
        loggedUser: loggedUserSlice.reducer,
        customers: customersSlice.reducer,
        customer: customerSlice.reducer,
        users: usersSlice.reducer,
        user: userSlice.reducer,
        vehicles: vehiclesSlice.reducer,
        vehicle: vehicleSlice.reducer,
        customerVehicles: customerVehiclesSlice.reducer,
        customerActiveVehicles: customerActiveVehiclesSlice.reducer,
        vehicleTrips: vehicleTripsSlice.reducer,
    },
})

export default store
