import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const customersSlice = createSlice({
    name: "customers",
    initialState: {
        data: null,
        loading: false,
        error: "",
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchCustomers.pending, (state, action) => {
            state.loading = true
            state.error = ""
        })
        builder.addCase(fetchCustomers.fulfilled, (state, action) => {
            state.data = action.payload
            state.loading = false
        })
        builder.addCase(fetchCustomers.rejected, (state, action) => {
            state.loading = false
            state.error = "Error while fetching customers"
        })
    },
})

export const fetchCustomers = createAsyncThunk(
    "fetchCustomers",
    async (data) => {
        const url = "https://wa.dukateknoloji.com/customers/"
        const config = {
            headers: {
                apiKey: data,
                "Content-Type": "application/x-www-form-urlencoded",
            },
        }
        const response = await axios.get(url, config)
        return response.data
    }
)

export const customersActions = customersSlice.actions

export default customersSlice
