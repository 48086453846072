import { useSelector } from "react-redux"
import { NavLink } from "react-router-dom"
import logoBlack from "../../images/alfa-smart-logo-black.svg"
import styles from "./Header.module.css"

export const Header = () => {
    const loggedUser = useSelector((state) => state.loggedUser)

    const activeStyle = {
        color: "#241c2d",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: " 14px",
        lineHeight: " 21px",
    }
    return (
        <div className={styles.header}>
            <img
                className={styles.logoBlack}
                src={logoBlack}
                alt="Alfa Smart logo"
            />
            <ul className={styles.links}>
                <li>
                    <NavLink
                        style={({ isActive }) =>
                            isActive ? activeStyle : undefined
                        }
                        to="/customers"
                    >
                        Müşteriler
                    </NavLink>
                </li>

                <li>
                    <NavLink
                        style={({ isActive }) =>
                            isActive ? activeStyle : undefined
                        }
                        to="/users"
                    >
                        Kullanıcılar
                    </NavLink>
                </li>

                <li>
                    <NavLink
                        style={({ isActive }) =>
                            isActive ? activeStyle : undefined
                        }
                        to="/vehicles"
                    >
                        Cihazlar{" "}
                    </NavLink>
                </li>
            </ul>

            <div className={styles.loggedUser}>
                <p>{loggedUser.data.name}</p>
            </div>
        </div>
    )
}
