import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import styles from "./CustomersListScreen.module.css"
import { fetchCustomers } from "../store/customers-slice"

import { CustomersListBody } from "../components/customers/CustomersListBody"
import { CustomersList } from "../components/customers/CustomersList"
import { deleteCustomer } from "../store/customer-slice"
import { NavLink } from "react-router-dom"

export const CustomersListScreen = () => {
    const dispatch = useDispatch()
    const loggedUser = useSelector((state) => state.loggedUser)

    const customers = useSelector((state) => state.customers)

    useEffect(() => {
        dispatch(fetchCustomers(loggedUser.data.apiKey))
    }, [])

    const deleteCustomerHandler = (customerId) => {
        const data = {
            customerId: customerId,
            apiKey: loggedUser.data.apiKey,
        }
        dispatch(deleteCustomer(data))
    }

    let renderItems
    if (customers.data) {
        renderItems = customers.data.customers.map((customer) => {
            return (
                <CustomersListBody
                    customer={customer}
                    deleteCustomerHandler={deleteCustomerHandler}
                    key={customer.musteriId}
                />
            )
        })
    }
    console.log(customers.data)

    return (
        <div className={styles.tableContainer}>
            {!!customers.data ? (
                <>
                    <div className={styles.tableInnerTopContainer}>
                        <div className={styles.leftContainer}>
                            <p>Müşteriler</p>
                            <p className={styles.customerNumber}>
                                {customers.data.customers.length}
                            </p>
                        </div>

                        <NavLink
                            className={styles.rightContainer}
                            to="/add-customer"
                        >
                            <p>Müşteri Ekle</p>
                            <i className="fa-solid fa-plus "></i>
                        </NavLink>
                    </div>
                    <CustomersList renderItems={renderItems} />
                </>
            ) : (
                "Loading"
            )}
        </div>
    )
}
