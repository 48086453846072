import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
const loggedUserSlice = createSlice({
    name: "user",
    initialState: {
        isAuth: false,
        data: null,
        loading: false,
        error: "",
    },
    reducers: {
        authentication(state) {
            state.isAuth = true
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loginUser.pending, (state, action) => {
            state.loading = true
            state.error = ""
        })
        builder.addCase(loginUser.fulfilled, (state, action) => {
            state.data = action.payload
            state.loading = false
        })
        builder.addCase(loginUser.rejected, (state, action) => {
            state.loading = false
            state.error = "Error while login"
        })
    },
})

export const loginUser = createAsyncThunk("loginUser", async (data) => {
    const url = "https://wa.dukateknoloji.com/securelogin/"
    const config = {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    }
    const response = await axios.post(url, data, config)
    return response.data
})

export const loggedUserActions = loggedUserSlice.actions

export default loggedUserSlice
