import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const vehiclesSlice = createSlice({
    name: "vehicles",
    initialState: {
        data: null,
        loading: false,
        error: "",
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchVehicles.pending, (state, action) => {
            state.loading = true
            state.error = ""
        })
        builder.addCase(fetchVehicles.fulfilled, (state, action) => {
            state.data = action.payload
            state.loading = false
        })
        builder.addCase(fetchVehicles.rejected, (state, action) => {
            state.loading = false
            state.error = "Error while fetching vehicles"
        })
    },
})

export const fetchVehicles = createAsyncThunk("fetchVehicles", async (data) => {
    const url = "https://wa.dukateknoloji.com/vehicles/"
    const config = {
        headers: {
            apiKey: data,
            "Content-Type": "application/x-www-form-urlencoded",
        },
    }
    const response = await axios.get(url, config)
    return response.data
})

export const vehiclesActions = vehiclesSlice.actions

export default vehiclesSlice
