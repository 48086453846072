import styles from "./UsersListScreen.module.css"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { UsersList } from "../components/users/UsersList"
import { UsersListBody } from "../components/users/UsersListBody"
import { deleteUser } from "../store/user-slice"
import { fetchUsers } from "../store/users-slice"
import { NavLink } from "react-router-dom"

export const UsersListScreen = () => {
    const dispatch = useDispatch()
    const loggedUser = useSelector((state) => state.loggedUser)
    const users = useSelector((state) => state.users)
    console.log(users)

    useEffect(() => {
        dispatch(fetchUsers(loggedUser.data.apiKey))
    }, [])
    console.log(users.data)

    const deleteUserHandler = (userId) => {
        const data = {
            userId: userId,
            apiKey: loggedUser.data.apiKey,
        }
        dispatch(deleteUser(data))
    }

    let renderItems
    if (users.data) {
        renderItems = users.data.users.map((user) => {
            return (
                <UsersListBody
                    user={user}
                    deleteUserHandler={deleteUserHandler}
                    key={user.kullaniciId}
                />
            )
        })
    }

    return (
        <div className={styles.tableContainer}>
            {!!users.data ? (
                <>
                    <div className={styles.tableInnerTopContainer}>
                        <div className={styles.leftContainer}>
                            <p>Kullanıcılar</p>
                            <p className={styles.userNumber}>
                                {users.data.users.length}
                            </p>
                        </div>

                        <NavLink
                            className={styles.rightContainer}
                            to="/add-user"
                        >
                            <p>Kullanıcı Ekle</p>
                            <i className="fa-solid fa-plus "></i>
                        </NavLink>
                    </div>
                    <UsersList renderItems={renderItems} />
                </>
            ) : (
                "Loading"
            )}
        </div>
    )
}
