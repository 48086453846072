import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const usersSlice = createSlice({
    name: "users",
    initialState: {
        data: null,
        loading: false,
        error: "",
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchUsers.pending, (state, action) => {
            state.loading = true
            state.error = ""
        })
        builder.addCase(fetchUsers.fulfilled, (state, action) => {
            state.data = action.payload
            state.loading = false
        })
        builder.addCase(fetchUsers.rejected, (state, action) => {
            state.loading = false
            state.error = "Error while fetching users"
        })
    },
})

export const fetchUsers = createAsyncThunk("fetchUsers", async (data) => {
    const url = "https://wa.dukateknoloji.com/users/"
    const config = {
        headers: {
            apiKey: data,
            "Content-Type": "application/x-www-form-urlencoded",
        },
    }
    const response = await axios.get(url, config)
    return response.data
})

export const usersActions = usersSlice.actions

export default usersSlice
