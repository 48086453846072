import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const customerSlice = createSlice({
    name: "customer",
    initialState: {
        data: null,
        loading: false,
        error: "",
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(addCustomer.pending, (state, action) => {
            state.loading = true
            state.error = ""
        })
        builder.addCase(addCustomer.fulfilled, (state, action) => {
            state.data = action.payload
            state.loading = false
        })
        builder.addCase(addCustomer.rejected, (state, action) => {
            state.loading = false
            state.error = "Error while adding customer"
        })
        builder.addCase(updateCustomer.pending, (state, action) => {
            state.loading = true
            state.error = ""
        })
        builder.addCase(updateCustomer.fulfilled, (state, action) => {
            state.data = action.payload
            state.loading = false
        })
        builder.addCase(updateCustomer.rejected, (state, action) => {
            state.loading = false
            state.error = "Error while updating customer"
        })
        builder.addCase(deleteCustomer.pending, (state, action) => {
            state.loading = true
            state.error = ""
        })
        builder.addCase(deleteCustomer.fulfilled, (state, action) => {
            state.data = action.payload
            state.loading = false
        })
        builder.addCase(deleteCustomer.rejected, (state, action) => {
            state.loading = false
            state.error = "Error while deleting customer"
        })
    },
})

export const addCustomer = createAsyncThunk("addCustomer", async (data) => {
    const url = "https://wa.dukateknoloji.com/customers/"
    const config = {
        headers: {
            apiKey: data.apiKey,
            "Content-Type": "application/x-www-form-urlencoded",
        },
    }
    const response = await axios.post(url, data.addCustomerInfo, config)
    return response.data
})

export const updateCustomer = createAsyncThunk(
    "updateCustomer",
    async (data) => {
        const url = `https://wa.dukateknoloji.com/customers/${data.customerId}/`
        const config = {
            headers: {
                apiKey: data.apiKey,
                "Content-Type": "application/x-www-form-urlencoded",
            },
        }
        console.log(data.updateCustomerInfo)

        const response = await axios.put(url, data.updateCustomerInfo, config)
        return response.data
    }
)

export const deleteCustomer = createAsyncThunk(
    "deleteCustomer",
    async (data) => {
        const url = `https://wa.dukateknoloji.com/customers/${data.customerId}/`
        const config = {
            headers: {
                apiKey: data.apiKey,
                "Content-Type": "application/x-www-form-urlencoded",
                "Access-Control-Allow-Credentials": true,
                "Access-Control-Allow-Origin": true,
                "Access-Control-Allow-Headers": true,
            },
        }
        const response = await axios.delete(url, config)
        return response.data
    }
)

export const customerActions = customerSlice.actions

export default customerSlice
