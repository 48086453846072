import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { LoadingScreen } from "../../screens/LoadingScreen"
import { addUser } from "../../store/user-slice"
import { UserInput } from "./UserInput"
import styles from "./AddUser.module.css"

export const AddUser = () => {
    const dispatch = useDispatch()
    const user = useSelector((state) => state.user)
    const userApiKey = useSelector((state) => state.loggedUser.data.apiKey)
    const [addUserInfo, setAddUserInfo] = useState({
        customerId: "",
        name: "",
        surname: "",
        phoneNumber: "",
        email: "",
        userName: "",
        password: "",
        userRights: "",
    })

    function handleChange(event) {
        const { name, value } = event.target
        setAddUserInfo((prevOptData) => {
            return {
                ...prevOptData,
                [name]: value,
            }
        })
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            addUserHandler()
            setAddUserInfo({
                customerId: "",
                name: "",
                surname: "",
                phoneNumber: "",
                email: "",
                userName: "",
                password: "",
                userRights: "",
            })
        } catch (error) {
            console.log(error)
        }
    }

    const addUserHandler = () => {
        const data = {
            addUserInfo: addUserInfo,
            apiKey: userApiKey,
        }
        dispatch(addUser(data))
    }
    console.log(addUserInfo)
    return (
        <div className={styles.formContainer}>
            {user.loading ? (
                <LoadingScreen />
            ) : (
                <UserInput
                    buttonText={"Kullanıcı Ekle"}
                    userInfo={addUserInfo}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                />
            )}
        </div>
    )
}
