import React from "react"
import {
    Marker,
    Popup,
    MapContainer,
    TileLayer,
    Polyline,
    useMap,
} from "react-leaflet"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
export const Map = () => {
    const params = useParams()

    const customerActiveVehicles = useSelector(
        (state) => state.customerActiveVehicles
    )
    const customerActiveVehicle =
        customerActiveVehicles.data.aracbilgileri.filter(
            (vehicle) => vehicle.cihazId === params.cihazId
        )
    console.log(customerActiveVehicle)

    const markerItems = customerActiveVehicle.map((vehicle) => {
        return (
            <Marker position={[vehicle.lat, vehicle.lon]}>
                <Popup>
                    <p>cihazId {vehicle.cihazId}</p>
                    <p>Plaka {vehicle.plaka}</p>
                    <p>Tarih {vehicle.Tarih}</p>
                    <p>Saat {vehicle.Saat}</p>
                    <p>Araç Türü {vehicle.aracTuru}</p>
                    <p>Hız {vehicle.Hiz}</p>
                    <p>GPS kalite {vehicle.GPSBaglantiKalitesi}</p>
                    <p>GSM sinyal gücü {vehicle.GSMSinyalGucu}</p>
                </Popup>
            </Marker>
        )
    })

    const mapStyle = {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
    }
    return (
        <div style={mapStyle}>
            <MapContainer
                center={[
                    customerActiveVehicle[0].lat,
                    customerActiveVehicle[0].lon,
                ]}
                zoom={15}
                scrollWheelZoom={true}
                style={{ height: "100vh" }}
            >
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {markerItems}
                {/* {firstLoc} */}

                {/* <Polyline pathOptions={{ color: "red" }} positions={path} /> */}
            </MapContainer>
        </div>
    )
}
