import React from "react"
import styles from "./VehiclesList.module.css"

export const VehiclesList = ({ renderItems }) => {
    return (
        <table className={styles.table}>
            <thead>
                <tr>
                    <th>Cihaz ID</th>
                    <th>Müşteri Ünvanı</th>
                    <th>Cihaz Tipi</th>
                    <th>Araç Türü</th>
                    <th>Cihaz Seri No</th>
                    <th>telefon</th>
                    <th>Plaka</th>
                </tr>
            </thead>
            <tbody>{renderItems}</tbody>
        </table>
    )
}
