import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"

const customerVehiclesSlice = createSlice({
    name: "customerVehicles",
    initialState: {
        data: null,
        loading: false,
        error: "",
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchCustomerVehicles.pending, (state, action) => {
            state.loading = true
            state.error = ""
        })
        builder.addCase(fetchCustomerVehicles.fulfilled, (state, action) => {
            state.data = action.payload
            state.loading = false
        })
        builder.addCase(fetchCustomerVehicles.rejected, (state, action) => {
            state.loading = false
            state.error = "Error while fetching vehicles"
        })
    },
})

export const fetchCustomerVehicles = createAsyncThunk(
    "fetchCustomerVehicles",
    async (data) => {
        const url = `https://wa.dukateknoloji.com/customer/vehicles/${data.customerId}/`
        const config = {
            headers: {
                apiKey: data.apiKey,
                "Content-Type": "application/x-www-form-urlencoded",
            },
        }
        const response = await axios.get(url, config)
        return response.data
    }
)

export const customerVehiclesActions = customerVehiclesSlice.actions

export default customerVehiclesSlice
