import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { LoadingScreen } from "../../screens/LoadingScreen"
import { addVehicle } from "../../store/vehicle-slice"
import { VehicleInput } from "./VehicleInput"
import styles from "./AddVehicle.module.css"

export const AddVehicle = () => {
    const dispatch = useDispatch()
    const vehicle = useSelector((state) => state.vehicle)
    const userApiKey = useSelector((state) => state.loggedUser.data.apiKey)
    const [addVehicleInfo, setAddVehicleInfo] = useState({
        vehicleSerialNo: "",
        deviceType: "",
        customerId: "",
        plate: "",
        vehicleType: "",
        phoneNumber: "",
        digitalIn1: "",
        digitalIn1Type: "",
        digitalIn2: "",
        digitalIn2Type: "",
        analogueIn1: "",
        analogueIn1Type: "",
        relay1: "",
        relay1Type: "",
        relay2: "",
        relay2Type: "",
    })

    function handleChange(event) {
        const { name, value } = event.target
        setAddVehicleInfo((prevOptData) => {
            return {
                ...prevOptData,
                [name]: value,
            }
        })
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            addVehicleHandler()
            setAddVehicleInfo({
                vehicleSerialNo: "",
                deviceType: "",
                customerId: "",
                plate: "",
                vehicleType: "",
                phoneNumber: "",
                digitalIn1: "",
                digitalIn1Type: "",
                digitalIn2: "",
                digitalIn2Type: "",
                analogueIn1: "",
                analogueIn1Type: "",
                relay1: "",
                relay1Type: "",
                relay2: "",
                relay2Type: "",
            })
        } catch (error) {
            console.log(error)
        }
    }

    const addVehicleHandler = () => {
        const data = {
            addVehicleInfo: addVehicleInfo,
            apiKey: userApiKey,
        }
        dispatch(addVehicle(data))
    }
    console.log(addVehicleInfo)
    return (
        <div className={styles.formContainer}>
            {vehicle.loading ? (
                <LoadingScreen />
            ) : (
                <VehicleInput
                    buttonText={"Cihaz Ekle"}
                    vehicleInfo={addVehicleInfo}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                />
            )}
        </div>
    )
}
