import React from "react"
import { NavLink } from "react-router-dom"
import styles from "./UserListBody.module.css"

export const UsersListBody = ({ user, deleteUserHandler }) => {
    return (
        <tr className={styles.tableRow}>
            <td>{user.kullaniciId}</td>
            <td>{user.musteriId}</td>
            <td>{user.ad}</td>
            <td>{user.soyad}</td>
            <td>{user.telefon}</td>
            <td>{user.kullaniciAdi}</td>
            <td>{user.sifre}</td>
            <td>{user.yetkiler}</td>
            <td>
                <NavLink to={`/update-user/${user.kullaniciId}`}>
                    <i
                        className={`fa-solid fa-pen-to-square fa-lg ${styles.updateIcon}`}
                    ></i>
                </NavLink>
                <i
                    className={`fa-solid fa-trash fa-lg ${styles.deleteIcon}`}
                    onClick={deleteUserHandler.bind(this, user.kullaniciId)}
                ></i>
            </td>
        </tr>
    )
}
