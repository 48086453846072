import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const vehicleSlice = createSlice({
    name: "vehicle",
    initialState: {
        data: null,
        loading: false,
        error: "",
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchVehicle.pending, (state, action) => {
            state.loading = true
            state.error = ""
        })
        builder.addCase(fetchVehicle.fulfilled, (state, action) => {
            state.data = action.payload
            state.loading = false
        })
        builder.addCase(fetchVehicle.rejected, (state, action) => {
            state.loading = false
            state.error = "Error while adding vehicle"
        })
        builder.addCase(addVehicle.pending, (state, action) => {
            state.loading = true
            state.error = ""
        })
        builder.addCase(addVehicle.fulfilled, (state, action) => {
            state.data = action.payload
            state.loading = false
        })
        builder.addCase(addVehicle.rejected, (state, action) => {
            state.loading = false
            state.error = "Error while adding vehicle"
        })
        builder.addCase(updateVehicle.pending, (state, action) => {
            state.loading = true
            state.error = ""
        })
        builder.addCase(updateVehicle.fulfilled, (state, action) => {
            state.data = action.payload
            state.loading = false
        })
        builder.addCase(updateVehicle.rejected, (state, action) => {
            state.loading = false
            state.error = "Error while updating vehicle"
        })
        builder.addCase(deleteVehicle.pending, (state, action) => {
            state.loading = true
            state.error = ""
        })
        builder.addCase(deleteVehicle.fulfilled, (state, action) => {
            state.data = action.payload
            state.loading = false
        })
        builder.addCase(deleteVehicle.rejected, (state, action) => {
            state.loading = false
            state.error = "Error while deleting vehicle"
        })
    },
})

export const fetchVehicle = createAsyncThunk("fetchVehicle", async (data) => {
    const url = `https://wa.dukateknoloji.com/vehicles/${data.vehicleId}/`
    const config = {
        headers: {
            apiKey: data.apiKey,
            "Content-Type": "application/x-www-form-urlencoded",
        },
    }
    const response = await axios.get(url, config)
    return response.data
})

export const addVehicle = createAsyncThunk("addVehicle", async (data) => {
    const url = "https://wa.dukateknoloji.com/vehicles/"
    const config = {
        headers: {
            apiKey: data.apiKey,
            "Content-Type": "application/x-www-form-urlencoded",
        },
    }
    const response = await axios.post(url, data.addVehicleInfo, config)
    return response.data
})

export const updateVehicle = createAsyncThunk("updateVehicle", async (data) => {
    const url = `https://wa.dukateknoloji.com/vehicles/${data.vehicleId}/`
    const config = {
        headers: {
            apiKey: data.apiKey,
            "Content-Type": "application/x-www-form-urlencoded",
        },
    }
    console.log(data.updateVehicleInfo)

    const response = await axios.put(url, data.updateVehicleInfo, config)
    return response.data
})

export const deleteVehicle = createAsyncThunk("deleteVehicle", async (data) => {
    const url = `https://wa.dukateknoloji.com/vehicles/${data.vehicleId}/`
    const config = {
        headers: {
            apiKey: data.apiKey,
            "Content-Type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Credentials": true,
            "Access-Control-Allow-Origin": true,
            "Access-Control-Allow-Headers": true,
        },
    }
    const response = await axios.delete(url, config)
    return response.data
})

export const vehicleActions = vehicleSlice.actions

export default vehicleSlice
