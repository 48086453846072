import React, { useEffect } from "react"
import styles from "./VehiclesListScreen.module.css"
import { useDispatch, useSelector } from "react-redux"
import { VehiclesList } from "../components/vehicles/VehiclesList"
import { VehiclesListBody } from "../components/vehicles/VehiclesListBody"
import { deleteVehicle } from "../store/vehicle-slice"
import { fetchVehicles } from "../store/vehicles-slice"
import { NavLink } from "react-router-dom"

export const VehiclesListScreen = () => {
    const dispatch = useDispatch()
    const loggedUser = useSelector((state) => state.loggedUser)
    const vehicles = useSelector((state) => state.vehicles)

    useEffect(() => {
        dispatch(fetchVehicles(loggedUser.data.apiKey))
    }, [])
    console.log(vehicles.data)

    const deleteVehicleHandler = (vehicleId) => {
        const data = {
            vehicleId: vehicleId,
            apiKey: loggedUser.data.apiKey,
        }
        dispatch(deleteVehicle(data))
    }

    let renderItems
    if (vehicles.data) {
        renderItems = vehicles.data.vehicles.map((vehicle) => {
            return (
                <VehiclesListBody
                    vehicle={vehicle}
                    deleteVehicleHandler={deleteVehicleHandler}
                    key={vehicle.cihazId}
                />
            )
        })
    }

    return (
        <div className={styles.tableContainer}>
            {!!vehicles.data ? (
                <>
                    <div className={styles.tableInnerTopContainer}>
                        <div className={styles.leftContainer}>
                            <p>Cihazlar</p>
                            <p className={styles.vehicleNumber}>
                                {vehicles.data.vehicles.length}
                            </p>
                        </div>

                        <NavLink
                            className={styles.rightContainer}
                            to="/add-vehicle"
                        >
                            <p>Cihaz Ekle</p>
                            <i className="fa-solid fa-plus"></i>
                        </NavLink>
                    </div>
                    <VehiclesList renderItems={renderItems} />
                </>
            ) : (
                "Loading"
            )}
        </div>
    )
}
